import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import ButtonLink from '../components/ButtonLink';
import Card from '../components/Card';
import Layout from '../components/layout/Layout';
import SEO from '../components/seo';

export default () => {
  const data = useStaticQuery(graphql`
    query {
      screenshot: file(relativePath: { eq: "screenshot.png" }) {
        childImageSharp {
          fluid(maxWidth: 1571) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      screenshot02: file(relativePath: { eq: "screenshot02.png" }) {
        childImageSharp {
          fluid(maxWidth: 2901) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      screenshot03: file(relativePath: { eq: "screenshot03.png" }) {
        childImageSharp {
          fluid(maxWidth: 995) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <Layout>
      <SEO />
      <section className="pt-20 md:pt-40">
        <div className="container mx-auto px-8 lg:flex">
          <div className="text-center lg:text-left lg:w-1/2">
            <h1 className="text-4xl lg:text-5xl xl:text-6xl font-bold leading-none">
              Автопот для Perfect World
            </h1>
            <p className="text-xl lg:text-2xl mt-6 font-light">
              Повышает выживаемость в пвп и на гвг, повышает комфорт в пве
            </p>
            <p className="mt-4 text-gray-600">
              Построен на AutoIt, и за подобные штуки{' '}
              <a
                target="_blank"
                rel="noreferrer"
                href="https://pwclassic.net/forum/index.php?threads/%D0%A4%D0%B0%D1%81%D1%82%D1%81%D0%B2%D0%B8%D1%87-%D1%81%D0%B5%D1%82%D0%BE%D0%B2-%D0%BA%D0%BB%D0%B8%D0%BA%D0%B5%D1%80%D0%BE%D0%BC-%D0%B8-%D0%B0%D0%B2%D1%82%D0%BE%D0%BF%D0%BE%D1%82%D1%8B.1703/#post-17327"
              >
                не банят
              </a>{' '}
              на pwclassic.net
            </p>
          </div>
          <div className="lg:w-1/2">
            <Img fluid={data.screenshot.childImageSharp.fluid} />
          </div>
        </div>
      </section>
      <section id="features" className="pt-24 lg:pt-48">
        <div className="container mx-auto text-center px-8">
          <h2 className="text-3xl lg:text-5xl font-semibold pb-8">Особенности</h2>
          <div className="grid grid-cols-1 gap-2 sm:grid-cols-2 sm:gap-4 lg:grid-cols-3 lg:gap-8">
            <Card>
              <p className="font-semibold text-xl">Настраиваемый</p>
              <p className="mt-4 text-left">
                Автопот настраивается таким образом, чтобы употреблять зелья только тогда, когда
                хп/мп падает до определенного значения. Вы сами выбираете точку на полоске хп/мп, в
                которой сработает автопот
              </p>
              <p className="mt-4 text-left">
                Можно настроить автопот таким образом, чтобы он не пытался спамить сосками/банками
                если персонаж мертв (чтобы не загаживать чат и не мешать писать)
              </p>
              <p className="mt-4 text-left">
                Можно отключить ненужные функции. Например, оставить автопот только на МП, а ХП и
                спам сосок отключить
              </p>
            </Card>
            <Card>
              <p className="font-semibold text-xl">Профили</p>
              <p className="mt-4 text-left">
                Можно настроить до 12 дополнительных профилей (итого 13) для разных ситуаций. Каждый
                профиль настраивается отдельно. Таким образом, у вас может быть отдельный профиль
                для пве (с пониженным расходом банок), для пвп, для гвг, а возможно и отдельный
                профиль для какого-то персонажа с отличающейся панелью
              </p>
            </Card>
            <Card>
              <p className="font-semibold text-xl">Спам сосок</p>
              <p className="mt-4 text-left">
                Можно настроить автопот таким образом, чтобы он спамил соски на маг/физ защиту. Эта
                функция незаменима во время гвг, когда на ручную активацию сосок просто нет времени
              </p>
            </Card>
            <Card>
              <p className="font-semibold text-xl">Привязка к окну</p>
              <p className="mt-4 text-left">
                Автопот привязывается к конкретному окну и работает только когда оно активно. Таким
                образом, он не мешает игре в окнах и работе других программ
              </p>
              <p className="mt-4 text-gray-600 text-left">
                Автопот технически не может работать с неактивным окном
              </p>
            </Card>
          </div>
        </div>
      </section>
      <section id="services" className="pt-24 lg:pt-48">
        <div className="container mx-auto px-8">
          <h2 className="text-3xl lg:text-5xl font-semibold text-center pb-8">
            Как начать пользоваться
          </h2>
          <ol className="list-decimal space-y-4">
            <li className="space-y-4">
              <div>Скачать архив с Яндекс Диска (по ссылке ниже)</div>
              <div className="lg:w-1/2 mx-auto border border-gray-500">
                <Img fluid={data.screenshot02.childImageSharp.fluid} />
              </div>
              <div>
                Chrome может выдавать предупреждение вида &quot;Этот файл редко скачивают, возможно
                он вредоносный&quot;. В таком случае нужно нажать на стрелку справа от этой надписи
                и выбрать &quot;Сохранить&quot;
              </div>
              <div className="lg:w-1/2 mx-auto border border-gray-500">
                <Img fluid={data.screenshot03.childImageSharp.fluid} />
              </div>
            </li>
            <li>Распаковать</li>
            <li>Открыть файл README.html - это инструкция по настройке</li>
            <li>Следовать инструкции</li>
          </ol>
          <div className="text-gray-600 mt-8">
            На каком-то из этапов может сработать антивирус. В таком случае следует добавить папку с
            автопотом в исключения. В автопоте нет никакого трояна (в архиве прилагается исходный
            код, его можно проверить и скомпилировать самому), но некоторые антивирусы реагируют то
            ли на команды нажатия клавиш, то ли на что-то ещё. Отчет вирустотала для исполняемого
            файла:{' '}
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.virustotal.com/gui/file/6894cefed9996cc5711b67b5c8b1cf6e0a713d313f896b246527fb77b65605ec/detection"
            >
              тыц
            </a>
            , для архива:{' '}
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.virustotal.com/gui/file/2326781d522fc4144cf3754323b3a8db49dc1349e77c4e92bf6e66a92760e653/detection"
            >
              тыц
            </a>
          </div>
        </div>
      </section>
      <section id="testimonials">
        <div className="container mx-auto px-8">
          <div className="my-20 py-24 bg-gray-200 rounded-lg text-center">
            <h3 className="text-5xl font-semibold">Готовы повысить свою эффективность в игре?</h3>
            <p className="mt-8 text-xl font-light">
              Скачайте автопот прямо сейчас, бесплатно и без регистрации
            </p>
            <p className="mt-16">
              <ButtonLink href="https://yadi.sk/d/YPqW-0nAmZugQg?w=1" size="xl">
                Скачать с Яндекс.Диск
              </ButtonLink>
            </p>
          </div>
        </div>
      </section>
    </Layout>
  );
};

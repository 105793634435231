import React from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';

const Header = () => (
  <header className="sticky top-0 bg-white shadow" style={{zIndex: 10}}>
    <div className="container flex flex-col sm:flex-row justify-between items-center mx-auto py-4 px-8">
      <div className="flex items-center text-2xl">
        PW Autopot
      </div>
      <div className="flex mt-4 sm:mt-0">
        <AnchorLink className="px-4" href="#features">
          Особенности
        </AnchorLink>
        <AnchorLink className="px-4" href="#services">
          Как пользоваться
        </AnchorLink>
      </div>
      <div className="hidden md:block">
        <AnchorLink
          className={`py-3 px-8 bg-primary
        hover:bg-primary-darker
        rounded
        text-white
        hover:text-white`}
          href="#testimonials"
        >
          Скачать
        </AnchorLink>
      </div>
    </div>
  </header>
);

export default Header;
